import api from "../../../api/api";


const getList = (filters) =>{
    return new Promise((resolve, reject)=>{
        api.get("/sleek/config/portal").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/sleek/config/portal",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const activate = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/sleek/config/portal/activate",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const portalServices = {
    getList,
    save,
    activate
}
export default portalServices;