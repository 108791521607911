import React, { useEffect, useState } from 'react'
import { useAlert } from '../../../../context/AlertContext';
import AppLayout from '../../../../layout/components/AppLayout';
import PortalForm from '../components/PortalForm';
import portalServices from '../portalServices';

function PortalListPage() {
    const {AppAlert} = useAlert();
    const [portalList,setPortalList] = useState(null)

    const getPortalList = () =>{
        portalServices.getList().then(res=>{
            setPortalList(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getPortalList();
    },[]);

    const activate = (data) =>{
        portalServices.activate(data).then(res=>{
            getPortalList();
            AppAlert("Whats App Response Portal Updated")
        }).catch(error=>{
            AppAlert("Shomthin gwent Wrong","error")
        })
    }

    return (
        <AppLayout>
            <div className='container'>
                {/*
                <div className='row'>
                    <div className='col-md-12'>
                        <span className='float-end'><PortalForm refreshList={getPortalList} /></span>
                    </div>
                </div>
                */}
                <div className='row'>
                    <div className='col-md-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Portal Id</th>
                                    <th>Display Name</th>
                                    <th>WhatsApp Number</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {portalList && portalList.map(portal=>
                                    <tr key={portal._id}>
                                        <td>{portal._id}</td>
                                        <td>{portal.portalId}</td>
                                        <td>{portal.displayName}</td>
                                        <td>{portal.whatsAppNo}</td>
                                        <td>
                                            {portal.active === false ?
                                                <button onClick={()=>activate(portal)} className='btn btn-danger' >Use This Portal For Whats App Response</button>
                                                :
                                                <span className='text-success' >Using This Portal For WhatsApp Response</span>
                                            }
                                        </td>
                                        <td>
                                            {/* <PortalForm refreshList={getPortalList} data={portal} /> */}
                                        </td>
                                    </tr>
                                )}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default PortalListPage