import moment from 'moment';
import React from 'react'

function PendingCounter({reportData,masters,getMastersLabel}) {

    const getTotalByType = (type) =>{
        let total = reportData.filter(data => data.grievanceType == type)
        return total.length;
    }

    const getDaysDiffrence = (inputDate) =>{
        return moment().diff(moment(inputDate),'days')   
    }

    const getOverDueByType = (type) =>{
        let total = reportData.filter(data => data.grievanceType == type && getDaysDiffrence(data.created_at) >= 7)
        return total.length;
    }

    const getPendingTodayByType = (type) =>{
        let total = reportData.filter(data => data.grievanceType == type && getDaysDiffrence(data.created_at) == 6)
        return total.length;
    }

    const getPendingTomorrowByType = (type) =>{
        let total = reportData.filter(data => data.grievanceType == type && getDaysDiffrence(data.created_at) == 5)
        return total.length;
    }

    const getNewTodayByType = (type) =>{
        let total = reportData.filter(data => data.grievanceType == type && getDaysDiffrence(data.created_at) <= 1)
        return total.length;
    }

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-12'>
                            {/*
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Grievance Type</th>
                                        <th>Diff</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData.map(grievance=>
                                        <tr key={grievance.id}>
                                            <td>{grievance.id}</td>
                                            <td></td>
                                            <td>{ moment().diff(moment(grievance.created_at),'days')} </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            */}



                            
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Grievance Type</th>
                                        <th className='bg-success text-white'>New Today</th>
                                        <th className='bg-info'>Pending Tomorrow</th>
                                        <th className='bg-warning'>Pending Today</th>
                                        <th className='bg-danger text-white'>Overdue</th>
                                        <th className='bg-primary text-white'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {masters['grievance-type'].map(grievanceType=>
                                        <tr>
                                            <th>{grievanceType.label}</th>
                                            <td className='bg-success text-white'>{getNewTodayByType(grievanceType.value)}</td>
                                            <td className='bg-info'>{getPendingTomorrowByType(grievanceType.value)}  </td>
                                            <td className='bg-warning'>{getPendingTodayByType(grievanceType.value)}</td>
                                            <td className='bg-danger text-white'>{getOverDueByType(grievanceType.value)}</td>
                                            <td className='bg-primary text-white'>{getTotalByType(grievanceType.value)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PendingCounter




{/*
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className="card bg-primary text-white shadow  p-1">
                                <p>Waiting</p>
                                <span className="text-center"><h1>10</h1></span>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                            <div className="card bg-info shadow p-1">
                                <p>Re Opened</p>
                                <span className="text-center"><h1>26</h1></span>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="card bg-warning shadow p-1">
                                <p>In Progress</p>
                                <span className="text-center"><h1>24</h1></span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card bg-success text-white shadow p-1">
                                <p>Closed</p>
                                <span className="text-center"><h1>25</h1></span>
                            </div>
                        </div>
                    </div>
                    */}