import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import PendingCounter from '../components/PendingCounter'
import reportsServices from '../reportsServices';
import useSystemMasterList from '../../../../hooks/useSystemMasterList';

function GrievancePendingReportPage() {

    const [reportData,setReportData] = useState(null);

    const [masters,getMasterLabel] = useSystemMasterList(['grievance-type','grievance-status']);

    const getPendingReport = () => {
        reportsServices.getPendingReport().then(res=>{
            setReportData(res.data);
        }).catch(error=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        getPendingReport()
    },[])

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'></div>
                </div>
                {reportData && masters && <PendingCounter reportData={reportData} masters={masters} getMasterLabel={getMasterLabel} /> }
            </div>
        </AppLayout>
    )
}

export default GrievancePendingReportPage